body {
  width: 100%;
  height: 100vh;
  background-color: #f1f2fa;
  overflow-x: hidden;
}

*{
  font-family: Poppins, sans-serif;
}

/* Header */

@media only screen and (min-width: 310px) and (max-width: 600px) {
  .project .proheading {
    font-size: 16px !important;
    margin-top: 20px;
    display: none;
  }

  .project-1 h4.proheading-1 {
    font-size: 18px !important;
    margin-right: -90px;
    margin-top: -45px;
  }

  .login-form {
    margin-top: 10px;
  }

  .click-user {
    margin-right: 65px;
  }
}

.header-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: lightblue;
  padding: 10px 15px;
  background-color: rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid rgb(231, 225, 225) !important;
}

.card-1 {
  border-radius: 10.7194px;
  box-shadow: 2.62925px 2.62925px 5.2585px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  border: none;
}

.card-1 .card-img-top {
  width: 75px;
  height: 80px;
  display: block;
  margin: auto;
  object-fit: cover;
  padding: 3px 0px 3px 0px;
}


.card-1-content .card-title {
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
  text-align: center;
  margin-top: 10px;
}

.card1 img {
  width: 100%;
  height: auto;
}

.signup-form .date-field {
  margin-top: 10px;
}

.signup-form .time-field {
  margin-top: 15px;
}

.cross-close-btn .btn-close {
  border: none !important;
  outline: none !important;
}

@media (max-width: 768px) {

  .login-form .welcome {
    font-size: 12px;
    margin-right: 4%;
    font-weight: bold;

  }

  .login-form .loginbutton {
    height: 25px;
    justify-content: center;
    line-height: 10px;
    margin-top: 0px;
    border-radius: 5px;
    font-size: 10px;
  }

  .login-form .loginbutton-log {
    height: 28px;
    justify-content: center;
    line-height: 10px;
    letter-spacing: 0px;
    margin-top: 0px;
    border-radius: 5px;
    font-size: 9px;
  }

  .App-header .App-logo {
    padding: 10px;
  }

  .App-header {
    height: 20px;
    margin-left: -20px;
    margin-top: -6px;
  }

  .signup-form .name-field {
    width: 100%;
  }

  .signup-form .contact-field {
    width: 100%;
  }

  .signup-form .email-field {
    width: 100%;
  }

  .signup-form .password-field {
    width: 100%;
  }

  .signup-form .date-field {
    width: 100%;
  }

  .signup-form .time-field {
    width: 100%;
  }

  .signup-form .birth-field {
    width: 100%;
  }

  .box-1 .cross-login-btn {
    font-size: 25px;
    margin-right: -10px;
    margin-top: -10px;
  }

  .login-for .login-heading {
    font-size: 16px;
  }

  .login-for .login-email-field {
    width: 100%;
    margin-bottom: 20px !important;
  }

  .login-for .login-password-field {
    width: 100%;
    margin: auto;
  }
}

.box .card {
  border: none;
}

.signup-form .signup-heading {
  font-size: 22px;
}

.login-for .forgot {
  text-decoration: none;
  color: #d73878;
  font-size: 14px;
  text-align: center;
  font-weight: 600;
}

.login-for .have-account {
  text-decoration: none;
  color: #d73878;
  font-size: 17px;
  text-align: center;
  font-weight: 700;
}

.login-for .login-field {
  width: 100%;
  margin-top: 20px;
}

/* styling of online-puja */

.banner {
  width: 100%;
  height: auto;
}

.container .banner {
  width: 100%;
  height: auto;
}

/* css of talk astro  */


.talkastro {
  background-color: white;
  padding: 20px;
  border-radius: 12px;
}

.talkastro-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.talkastro h5 {
  font-weight: 700;
  font-size: 19px;
}

.talkastro p {
  font-size: 15px;
}

.talkastro #talk-para {
  color: gray;
}

.imgdata {
  margin-top: 10px;
  width: 130px;
  height: 130px;
  object-fit: cover;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
}

.card3 {
  position: relative;
}

.card3 .rname {
  font-weight: 700;
  font-size: 14px;
  width: 130px;
  min-height: 50px;
}

.rating-1 span {
  display: flex;
  align-items: center;
  color: black;
}

.card3 .rate {
  color: #d73878;
  font-size: 16px;
}

.card3 .card-btn {
  background-color: #fff1e8;
  color: #d73878;
  width: 130px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  height: 32px;
  display: flex;
  align-items: center;
}

.card-btn .span-1 {
  font-weight: 700;
  font-size: 14px;
}

.card-btn .btn-1 {
  color: #ffffff;
  background-color: #d73878;
  border-radius: 4px;
  font-weight: 600;
  height: 32px;
  display: flex;
  align-items: center;
  margin-right: 12px;
  width: 30px;
  justify-content: center;
  border: none;
}

@media only screen and (min-width: 310px) and (max-width: 600px) {
  .item-section {
    overflow-x: auto;
    /* white-space: nowrap; */
  }

  .item-section .talkdata {
    display: flex;
    flex-wrap: nowrap;
  }

  .item-section .talkdata .change-row {
    flex: 0 0 auto;
    margin-right: 1px;
  }

  .item-section::-webkit-scrollbar {
    display: none;
  }

  .item-section {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .card3, .card5 {
      flex: 0 0 auto;
      width: 54%;
  }

}

@media only screen and (min-width: 310px) and (max-width: 600px) {
  .talkdata-1 {
    width: 50%;
    flex-wrap: nowrap;
    
  } 
}


/* styling of banner1 */

.container .personal {
  font-family: Berkshire Swash;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 8px;
}

.graha-shanti {
  border-radius: 12px;
  padding: 18px 15px;
  border: none;
  background-color: white;
}

.graha-shanti img {
  border-radius: 12px;
  width: 100%;
  height: auto;
}

.graha-shanti h3 {
  color: #414449;
  font-family: Berkshire Swash;
  font-size: 13px;
  margin: 5px 0 8px;
}

.graha-shanti h4 {
  color: #414449;
  font-family: Berkshire Swash;
  font-size: 14px;
  margin: 5px 0 8px;
  font-weight: 300;
  text-align: left;
  margin-top: 10px;
}

.graha-shanti .participate-now {
  width: 200px;
  display: block;
  margin: auto;
  margin-top: 10px;
}

/* styling of puja sevash */

.puja-sevash {
  border-radius: 12px;
  border: none;
  background-color: white;
}

.scroller {
  height: 700px;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}

.scrollers {
  height: 150px;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
}

.puja-sevash img {
  border-radius: 12px;
  width: 100%;
  height: auto;
}

.puja-container {
  display: flex;
  align-items: center;
}

.puja-container .step-1 {
  border-radius: 15px;
  width: 235px;
  height: 120px;
  background-color: rgba(247, 143, 50, 0.2);
  margin: 0px 5px;
  display: flex;
  border: none;
}

.puja-step .step-1 .step-2 img {
  width: 80px;
  height: 115px;
}

.ganesh-1 {
  border: none;
  background-color: white;
  margin-top: 20px;
}

.collection {
  border-radius: 10px;
  background: rgba(247, 143, 50, 0.2);
  width: 100%;
  height: auto;
  margin-top: 20px;
}

.puja-sevash-1 {
  border-radius: 12px;
  background-color: white;
}

.puja-sevash-1 .input-1 {
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 10px;
  margin: 10px 20px;
}

.puja-sevash-1 .consultation {
  margin: 10px 20px;
  padding: 12px;
  font-weight: 600;
  background: #d73878;
  color: #fff;
  border: none;
  border-radius: 6px;
  min-width: 250px;
  font-size: 20px !important;
  letter-spacing: 1px;
}

.contact-us {
  margin-top: 20px;
}

/* styling of contact us form */

.form-control {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.privacy-container .for-submit {
  display: flex;
  justify-content: center;
  align-items: center;
}

.privacy-container .forgot-submit {
  border: none;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 35px;
  background-color: rgb(233, 97, 7);
  color: white;
  font-size: 20px;
  font-weight: 500;
}

/* styling of horoscope card */

.horoscope {
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  width: 100%;
  height: auto;
}

.horoscope p {
  color: #76788c;
  font-size: 14px;
}

.horoscope-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.horoscope-content h5 {
  font-weight: 700;
  font-size: 19px;
}

.imagedata {
  margin-top: 10px;
  width: 145px;
  height: 80px;
  border-radius: 10px;
}

.horoscope .listname {
  font-size: 16px;
  margin-top: 5px;
  line-height: 17px;
  font-weight: bold;
  width: 130px;
  min-height: 50px;
}

.horoscope p.rat {
  color: #d73878;
  font-size: 14px;
  font-weight: 700;
}

.card4-btn {
  background-color: #f8d9e4;
  color: #d73878;
  width: 100%;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  height: 32px;
  display: flex;
  align-items: center;
}

.card4-btn .span-2 {
  font-weight: 700;
  font-size: 14px;
}

.card4-btn .btn {
  color: #ffffff;
  background-color: #d73878;
  border-radius: 4px;
  font-weight: 600;
  height: 32px;
  display: flex;
  align-items: center;
  margin-right: 20px;
  width: 30px;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .imagedata {
    width: 100%;
    height: auto;
  }
}

/* styling of chat card*/

.chatdata {
  background-color: white;
  padding: 20px;
  border-radius: 12px;
}

.chat-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.chatdata h5 {
  font-weight: 700;
  font-size: 19px;
}

.chatdata p {
  font-size: 14px;
  color: rgb(2, 2, 2);
}

.imgdat {
  margin-top: 10px;
  width: 130px;
  height: 140px;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
}

.card5 .rname {
  font-weight: bold;
  font-size: 14px;
  width: 130px;
  min-height: 50px;
}

.card5 {
  position: relative;
}

.card5 .rate {
  color: #d73878;
  font-size: 16px;
}

.card5 .card-btn {
  background-color: #ebfffc;
  color: #ebfffc;
  width: 130px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  height: 32px;
  display: flex;
  align-items: center;
}

.card-btn .span-3 {
  font-weight: 700;
  font-size: 14px;
  color: #2d9180;
}

.card-btn .btn {
  color: #ebfffc;
  background-color: #2d9180;
  border-radius: 4px;
  font-weight: 600;
  height: 32px;
  display: flex;
  align-items: center;
  margin-right: 12px;
  width: 30px;
  justify-content: center;
}

/* styling of dailyscope */

.dailyscope {
  background-color: white;
  padding: 20px;
  border-radius: 12px;
}

.daily-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.imdata {
  margin-top: 5px;
  width: 2.5rem;
  height: 50px;
  border-radius: 10px;
}

.daily-horoscop {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.card6 {
  width: 100px;
  background: rgba(255, 255, 255, 0.6) !important;
  display: flex;
  justify-content: center;
  height: 100px;
  border-radius: 50%;
  align-items: center;
  flex-direction: column;
}

.card6 .card-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  background: #f8d9e4 !important;
  height: 90px;
  border-radius: 50%;
}

.card6 h5.name {
  margin-top: 20px;
  font-size: 15px;
  font-weight: 700;
}

.dailyscope .daily-content h5 {
  font-weight: 700;
  font-size: 19px;
}

.dailyscope p {
  color: gray;
}

.seeyours {
  color: #d73878;
  font-weight: 500;
}

/* styling of daily feed scope */

.feedscope {
  background-color: white;
  padding: 20px;
  border-radius: 12px;
}

.feed-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.imd {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin: 3px;
}

.feedscope h5 {
  font-weight: 800;
}

.watch {
  color: #d73878 !important;
  font-weight: 700 !important;
}

.feedscope p {
  font-weight: 400;
  color: grey;
}

/* Styling of astrologer*/

.astrologer {
  /* margin-left: 45px; */
  /* padding: 20px; */
  border-radius: 12px;
}

.card8 {
  background-color: white;
  border-radius: 10px;
  /* border-radius: 20px; */
  padding: 15px;
}

/* @media (max-width: 768px) {
  .card-body {
    font-size: 14px;
  }
} */

.card8 img {
  width: 100%;
  height: auto;
  border: 2px solid #f1f2fa;
  border-radius: 4px;
}

.card8 .card8-content {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.card8 .exp {
  font-size: 14px;
  color: gray;
  margin-top: -15px;
}

.card8 .language {
  font-size: 14px;
  color: gray;
  margin-top: -15px;
}

.card8 .expertise {
  font-size: 14px;
  color: gray;
  margin-top: -15px;
}

.card8 .card8-content .name {
  font-size: 14px;
  font-weight: 700;
  word-spacing: -1px;
}

.card8 .card8-content .rates {
  color: #d73878;
  font-size: 14px;
  font-weight: 700;
}

.card8 button {
  background-color: #d73878;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  width: 90%;
  border: none;
  border-radius: 4px;
  padding: 6px;
  /* margin-top: 10px; */
  cursor: pointer;
  font-weight: bold;
}

/* styling of monthlyscope*/

.monthlyscope {
  border-radius: 12px;
}

/* Card9 */

.card9 {
  border-radius: 10px;
  background-color: white;
}

.card9 img {
  border-radius: 10px;
}

.card9 h3 {
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 5px;
}

.card9 .p {
  font-size: 14px;
  font-weight: 400;
}

.card9 .card9-content {
  display: flex;
  justify-content: space-between;
}

.card9 .card9-content .p1 {
  color: #d73878;
  font-size: 16px;
  font-weight: 700;
}

.card9 .card-body h3 {
  font-size: 16px;
  font-weight: 700;
}

.card9 .card9-content .both-btn {
  background-color: #f8d9e4;
  color: #d73878;
  width: 120px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  height: 35px;
  display: flex;
  align-items: center;
}

.scroll-container {
  width: 100%; 
  /* overflow: hidden;  */
  position: relative;
  margin-top: -25px;
  margin-bottom: -20px;
}

.scroll-text {
  display: inline-block;
  white-space: nowrap; 
  font-family: Georgia, 'Times New Roman', Times, serif; 
  font-weight: 700;
  color: #d73878;
  animation: scroll-text 25s linear infinite; 
  /* animation: scroll 20s linear infinite; */
}

@keyframes scroll-text {
  0% {
    transform: translateX(700%); 
  }
  100% {
    transform: translateX(-100%); 
  }
}

.scroll-text h4 {
  margin: 0; 
}

.card9 .card9-content .both-btn .span-2 {
  font-weight: 700;
  font-size: 14px;
}

.card9 .card9-content .both-btn .btn-btn {
  color: #e0e3f1;
  background-color: #d73878;
  border-radius: 8px;
  font-weight: 600;
  height: 35px;
  display: flex;
  align-items: center;
  margin-right: 12px;
  width: 35px;
  justify-content: center;
  border: none;
}

/* styling of chatastrosearch */

.details {
  /* margin-left: 45px; */
  /* padding: 20px; */
  border-radius: 12px;
}

.card10 {
  background-color: white;
  border-radius: 20px;
  padding: 15px;
}

.card10 img {
  border: 2px solid #f1f2fa;
  border-radius: 4px;
}

.card10 .card10-content {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}


.card10 .card10-content h5 {
  word-spacing: -1px;
  font-weight: 700;
}

.card10 .exp {
  font-size: 14px;
  color: gray;
}

.card10 .language {
  font-size: 14px;
  color: gray;
  margin-top: -15px;
}

.card10 .expertise {
  font-size: 14px;
  color: gray;
  margin-top: -15px;
}

.card10 .card10-content .name {
  font-size: 14px;
  font-weight: bold;
}

.card10 .card10-content .rates {
  color: #038e00;
  font-size: 16px;
  font-weight: 700;
}

.card10 button.buynow {
  background-color: #d73878;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  width: 90%;
  border: none;
  border-radius: 4px;
  padding: 6px;
  margin-top: 10px;
  cursor: pointer;
}

/* Styling of rashicard data */

.card11 {
  border: none;
  background-color: transparent;
}

.card11 .card-header {
  border: none;
  background-color: #f8d9e4;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.card11 .card-body {
  text-align: center;
}

.card11 img {
  width: 45px;
  height: auto;
}

.card-body h5.nam {
  font-size: 15px;
  font-weight: 800;
}

.card-body h5.months {
  margin-top: 5px;
  font-size: 15px;
}

/* styling of acharya prem */

.main-ajay {
  background-color: #f1f2fa;
  margin-bottom: 50px;
}

.acharya-ajay {
  /* border: 1px solid rgb(236, 228, 228); */
  width: 100%;
  background: white;
}

.card12 {
  background-color: white;
  border-radius: 10px;
  border: none;
}

.card12 img.history {
  width: 100%;
  height: auto;
  padding: 10px 50px;
}

.card12 h6 {
  font-weight: 700;
  margin-left: 10px;
  margin-top: 10px;
}

.card12 p.experiance {
  margin-left: 10px;
  margin-top: -5px;
}

.card12 p.languages {
  margin-left: 10px;
  margin-top: -15px;
}

.card12 p.expertise {
  margin-left: 10px;
  margin-top: -10px;
}

.card13 {
  background-color: white;
  border-radius: 10px;
  width: 100%;
  height: auto;
  margin-top: 15px;
  border: none;
}

.card13 .rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card13 .head h6 {
  font-weight: 700;
  margin: 25px;
}

.rating .head1 {
  margin-right: 15px;
  font-size: 18px;
}

.card14 {
  background-color: white;
  justify-content: space-between;
  width: 100%;
  height: auto;
  border-radius: 10px;
  padding: 30px 40px;
}

.card14 .audio-call {
  border: 2px solid rgb(247, 243, 243);
  justify-content: center;
}

.card14 .audio-call .audio-rupay {
  margin-top: 5px;
}

.card14 .audio-call .audio-rupay p.para {
  color: #4b60bc;
  font-weight: bold;
  margin-left: -50px;
}

.card14 .audio-call .audio-rupay p.para1 {
  font-weight: bold;
  margin-bottom: 10px;
  color: #d73878;
  margin-top: -18px;
  margin-left: -50px;
}

.card14 .audio-call button {
  color: white;
  margin-top: 8px;
  padding: 10px;
  font-weight: bold;
  width: 100%;
  height: auto;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  align-items: center;
  background: (269.7deg, #ffcdac -25.16%, #ffa468 35.91%, #d73878 94.38%);
}

.card14 .unavailable {
  border: 2px solid rgb(247, 243, 243);
  justify-content: center;
  align-items: center;
}

.card14 .unavailable p.para2 {
  color: #4b60bc;
  font-weight: bold;
  margin-left: -50px;
}

.card14 .unavailable .unavailable-rupay {
  margin-top: 5px;
}

.card14 .unavailable p.para {
  font-weight: bold;
  margin-bottom: 10px;
  color: #d73878;
}

.card14 .unavailable p.para3 {
  font-weight: bold;
  color: #d73878;
  margin-top: -18px;
  margin-left: -50px;
}

.card14 .unavailable button {
  background: rgb(165, 163, 163);
  color: white;
  padding: 10px;
  font-weight: bold;
  width: 100%;
  height: auto;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  align-items: center;
}

.card14 .acharya {
  display: flex;
  justify-content: space-between;
}

.card14 .acharya .about-me {
  width: 100%;
  height: auto;
}

.card14 .acharya .about-me h6 {
  margin-top: 30px;
  font-weight: 700;
}

.card14 .acharya .about-me p {
  font-size: 16px;
}

.card14 .acharya .choose h6 {
  margin-top: 15px;
  font-weight: 700;
}

@media (max-width: 768px) {
  .card12 img.history {
    width: 50%;
    height: 50%;
  }

  .card14 .unavailable p.para3 {
    margin-left: 20px;
  }

  .card14 .unavailable p.para2 {
    margin-left: 20px;
  }

  .card14 .audio-call .audio-rupay p.para1 {
    margin-left: 20px;
  }

  .card14 .audio-call .audio-rupay p.para {
    margin-left: 20px;
  }

  .card14 .audio-call button {
    width: 100px;
    padding: 6px;
    border-radius: 5px;
    margin-left: -50px;
  }

  .card14 .unavailable button {
    width: 100px;
    padding: 5px;
    border-radius: 6px;
    margin-left: -50px;
  }
}

/* styling of footer */

.linkhover a:hover {
  margin-left: 2px;
  transition: 0.2s ease-in-out !important;
}

h4 {
  text-align: center;
  margin-bottom: 30px;
}

.form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  margin-left: 400px;
}

.textField {
  width: 25%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-left: 400px;
  align-items: center;
  justify-content: center;
}

.primary-button {
  padding: 10px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 25%;
  margin-left: 400px;
}

.primary-button:hover {
  background-color: #0056b3;
}

.error {
  margin-top: 10px;
  color: red;
}

.login-form {
  display: flex;
  align-items: center;
  gap: 10px;
}

.App-logo {
  width: 65px;
  height: auto;
}

/* styling of wallet section */

.card16 {
  width: 100%;
  height: 60px;
  background-color: white;
  border-radius: 10px;
}

.card16 h6 {
  font-weight: 700;
  margin-left: 30px;
  margin-top: 20px;
  color: #d73878;
}

.promotional {
  background-color: white;
  height: 60px;
  border-radius: 10px;
  margin-top: 10px;
  padding-top: 20px;
}

.promotional .balance h6 {
  font-weight: 700;
  margin-left: 30px;
}

.pay-rupee {
  align-items: center;
  margin: 10px 0px;
  background-color: white;
  cursor: pointer;
  border: 1px solid #ccc;
  display: flex;
}

.pay-rupee.selected {
  background-color: #d73878;
  color: white;
}

.pay-rupee .wallet-paise {
  justify-content: center;
}

.proceed {
  background-color: orange;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;

}

.card18 .proceed .proceed-1 {
  font-size: 16px;
  width: 100%;
  background-color: #d73878;
}

.card18 .wallet-container {
  display: flex;
  justify-content: space-between;
}

.card18 .user {
  display: flex;
  justify-content: space-between;
  width: 500px;
  margin: 10px;
  padding: 25px;
  border: 1px solid rgb(206, 203, 203);
  border-radius: 5px;
}

.card18 .user-form {
  margin: 0px 0px 0px 10px;
}

@media only screen and (max-width: 500px) {
  .card18 .user {
    width: 94%;
  }
}

.user-1,
.user-5,
.user-8,
.user-12,
.user-15 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.user-3,
.user-4,
.user-6,
.user-7,
.user-9,
.user-10,
.user-13,
.user-14,
.user-16 {
  display: flex;
  flex-direction: column;
}

.male {
  display: inline-flex;
  flex-direction: row;
}

.marital {
  display: flex;
  justify-content: space-between;
}

.user-form .submit-data {
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-top: 20px;
  width: 100%;
  padding: 10px;
  background-color: #d73878;
  color: white;
  font-weight: bold;
  font-size: 20px;
  border: none;
}

.form-control {
  padding: 10px 30px;
  margin-top: 5px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.wallet-container h5 {
  font-weight: 700;
  margin-left: 30px;
  font-size: 18px;
}

.wallet-container h4 {
  margin-right: 30px;
  color: rgb(13, 236, 13);
  font-weight: 700;
  font-size: 20px;
}

.main-ask-contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  width: 100%;
}

.main-contact form TextField {
  height: 10px;
}

.form-detail {
  margin-left: 65px;
}


.privacy-container-1 {
   padding: 10px;
    background: white;
    border-radius: 10px;
}
/* styling of privacypolicy */

.privacy-policy {
  border: 1px solid rgb(206, 196, 196);
  width: 100%;
  background: white;
}

.privacy-container {
  padding: 20px;
  background: white;
  border-radius: 10px;
  margin: 15px 40px;
}

.privacy-heading {
  text-align: center;
}

.privacy {
  margin: 15px;
}

.privacy ul,
.privacy ol {
  font-size: 14px;
  font-weight: 500;
}

/* styling of order History */

.container .order {
  width: 100%;
  height: auto;
  border: none;
}

.order {
  padding: 20px;
}

.order .filter h6 {
  font-size: 30px;
}

.order .filter {
  display: flex;
}

.order .filter .filter-1 {
  padding-left: 10px;
}

.order .filter .filter-1 .filter-2 {
  padding: 8px;
  border: 2px solid black;
  border-radius: 5px;
}

.order .product {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  color: blue;
}

.order .product h6 {
  font-size: 20px;
}

/* styling of pending order */

.container .pending {
  display: flex;
  justify-content: space-between;
}

.container .pending h5 {
  color: blue;
  font-size: 25px;
}

/* styling of termsandConditions */

.terms-conditions {
  border: 1px solid rgb(206, 196, 196);
  width: 100%;
  background: white;
}

.terms-container {
  padding: 20px;
  background: white;
  border-radius: 10px;
  margin: 15px 40px;
}

.terms-heading {
  display: flex;
  justify-content: center;
  text-decoration: underline;
}

.terms ol {
  font-size: 13.5px;
  font-weight: 500;
}

/* styling of disclaimer */

.disclaimer-container {
  padding: 20px;
  background: white;
  border-radius: 10px;
  margin: 15px 40px;
}

.disclaimer-heading {
  display: flex;
  justify-content: center;
  text-decoration: underline;
}

/* styling of Daily Panchang */

.panchang-container {
  padding: 20px;
  background: white;
  border-radius: 10px;
  margin: 15px 40px;
}

.panchang-1 {
  padding: 20px;
  background: #fff1e8;
  margin: 10px 20px;
}

.user-form .form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  outline: 0;
  box-shadow: none;
  border: 3px solid black;
}

.date-field:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  outline: 0;
  box-shadow: none;
  border: 1px solid rgb(62, 62, 231);
}

.panchang-2 {
  margin: 15px 20px;
}

.acharya-ajay {
  background: white;
  /* border: 1px solid rgb(188, 184, 184); */
}

/* styling of Ask Question */

.ask-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ask-header h5 {
  font-family: 700;
  margin-left: 40px;
}

.ask-header Button {
  align-items: center;
  margin-right: 500px;
}

.main {
  margin: 0px 40px;
}

.choose-category {
  background: #fff;
  box-shadow: 2.62925px 2.62925px 5.2585px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-top: 16px;
}

.main-ask-header {
  padding: 10px;
  background-color: #d73878;
  width: 100%;
  top: 60px;
  left: "14% !important";
  z-index: 999;
  color: white;
}

.question-here {
  background: #fff;
  box-shadow: 2.62925px 2.62925px 5.2585px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 16px;
  margin-top: 16px;
}

.choose-question {
  background: #fff;
  box-shadow: 2.62925px 2.62925px 5.2585px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 16px;
  margin-top: 16px;
}

.main-ask-contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  width: 100%;
}

@media (max-width: 768px) {
  .ask-header {
    height: 25px;
  }

  .ask-header .currency {
    font-size: 15px;
  }

  .ask-header .fm {
    height: 4px;
    font-size: 14px;
    margin-top: -30px;
    font-weight: 300;
    line-height: 6px;
  }

  .ask-header button.fg {
    height: 30px;
    margin-left: 130px;
    align-items: center;
    justify-content: center;
    text-transform: none;
    line-height: 10px;
    margin-top: 0px;
    border-radius: 5px;
  }

  .choose-category {
    margin-top: -35px;
  }
}

/* BrahaspatiGraha  */

.brahaspati {
  background-color: white;
  border-radius: 10px;
  border-radius: 10px;
}

.brahaspati img.graha-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.brahaspati p {
  padding: 10px 15px;
}

.container .proceed-pandit {
  width: 100%;
  padding: 12px;
  color: white;
  background-color: #4b60bc;
  border-radius: 15px;
  border: none;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  margin-top: 18px;
  align-items: center;
}

.container .proceed-pandit .left-sight span {
  font-weight: 700;
  font-size: 16px;
}

.container .proceed-pandit .right-sight span {
  font-weight: 700;
}

/* styling of Choose Pandit Section */

.container .card20-content {
  border-radius: 10px;
  width: 100%;
  height: auto;
  background-color: white;
  padding: 15px 25px;
}

.container .card20-content img {
  border-radius: 4px;
  border: 2px solid #f1f2fa;
}

.container .card20-content .card20-content-1 {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.container .card20-content .card20-content-1 .vivek {
  font-weight: 700;
}

.container .card20-content .card20-content-1 .v-rate {
  color: #038e00;
  font-size: 16px;
  font-weight: 700;
}

.container .card20-content p {
  margin-top: -15px;
}

.container .card20-content button {
  background-color: #d73878;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  width: 80%;
  border: none;
  border-radius: 4px;
  padding: 8px;
  margin-top: 2px;
  cursor: pointer;
  font-weight: bold;
}

.container .card20-content .select {
  font-weight: 700;
}

.box-1 {
  width: 400px;
  margin: 10% auto 0px auto;
}

@media only screen and (max-width: 500px) {
  .box-1 {
    width: 90%;
    margin: 10% auto 0px auto;
    padding: 30px;
  }
}

.box-1 {
  padding: 30px !important;
}

.box {
  width: 500px;
  margin: 10% auto 0px auto;
  padding: 30px !important;
}

@media only screen and (max-width: 510px) {
  .box {
    width: 90%;
    margin: 10% auto 0px auto;
    padding: 30px;
  }
}

.login-for .login-email-field {
  width: 100%;
  margin: 20px auto 0px auto;
}

.login-password-field {
  width: 100%;
}

.profile .profile-card {
  padding: 40px;
  border: none;
}

.profile .profile-card .user-heading {
  background-color: rgb(248, 245, 245);
  padding: 6px;
  width: 100%;
  margin-top: -40px;
}

.profile .profile-card img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: block;
  margin: auto;
}

.profile .profile-card .user-profile h6 {
  text-align: center;
  font-weight: 600;
  margin-top: -5px;
}

.header-container .login-form button {
  background-color: #d73878;
}

.login-modal .login-for button.login-field {
  background-color: #d73878;
}

.signup-form button.signup-field {
  background-color: #d73878;
}

.have-account Link {
  color: #d73878 !important;
}

.puja-sevash-1 .consultation1 {
  background: #d73878;
  color: #fff;
  width: 100%;
  font-weight: bold;
  padding: 10px;
}

.puja-sevash-1 .input-2 {
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 10px;
  margin: 10px 20px;
}

.puja-sevash-1 .form-control {
  padding: 10px 15px;
  margin-top: 5px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.acharya-ajay .project-1 .proheading-1 {
  font-size: 24px;
  padding: 8px;
}
.App {
  text-align: center;
  padding: 20px;
}

.form {
  display: inline-block;
  text-align: left;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.input {
  margin: 10px;
}

button {
  padding: 10px;
  /* margin-top: 20px; */
}

.result {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #4CAF50;
  border-radius: 10px;
  background-color: #f1f1f1;
}
/* src/Style.css */

.App {
  text-align: center;
  padding: 20px;
}

.form {
  display: inline-block;
  text-align: left;
  padding: 20px;
  border: 1px solid #ffffff;
  background-color: white;
  border-radius: 4px;
  margin-top: 20px;
  max-width: 800px;
  width: 100%;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
}

/* button {
  margin-top: 20px;
} */

.footer {
  margin-top: 40px;
  text-align: center;
}

footer p {
  margin-top: 10px;
}

.TextField {
  margin-bottom: 15px;
}


.match-kundli-btn{
  background-color: #d73878 !important;
  color: white !important;
}

.header-menuitem{
  background-color: transparent !important;
  color: black !important;
  text-transform: capitalize !important;
}
/* Dashboard Link button */
.dashborad-link-btn {
  position: fixed;
  right: 0px;
  top: 35%;
  display: grid;
}
 
.dashborad-link-btn .dashboard-btn {
  width: 50px;
  height: 50px;
  color: white;
  font-size: 25px;
  border: 1px solid white;
  outline: none;
  position: relative;
  right: 0px;
}
 
.dashborad-link-btn .dashboard-btn a{
  color: white;
}
 
.dashborad-link-btn .btn1 {
  background-color: #1bd741;
}
 
.dashborad-link-btn .btn2{
  background: #f09433;
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
}
 
.dashborad-link-btn .btn3 {
  background-color: #234999;
}
 
.dashborad-link-btn .btn4 {
  background-color: #ec2828;
}
 
.dashborad-link-btn .btn5 {
  background-color: #0077b7;
}
 
.dashborad-link-btn .btn1:hover {
  transform: translateX(-10%);
  transition: all .2s ease-in-out;
}
 
.dashborad-link-btn .btn2:hover {
  transform: translateX(-10%);
  transition: all .2s ease-in-out;
}
 
.dashborad-link-btn .btn3:hover {
  transform: translateX(-10%);
  transition: all .2s ease-in-out;
}
 
.dashborad-link-btn .btn4:hover {
  transform: translateX(-10%);
  transition: all .2s ease-in-out;
}
 
.dashborad-link-btn .btn5:hover {
  transform: translateX(-10%);
  transition: all .2s ease-in-out;
}
/* Yearly Horoscope */
.yearly-horoscope h1 {
  text-align: center;
  font-weight: 600;
  font-size: 32px;
  color: #313131;
  margin-top: 20px;
}
 
.yearly-horoscope h2 {
  color: #616161;
  font-size: 21px;
  text-align: center;
  font-weight: 600;
}
 
.yearly-horoscope img {
  display: block;
  margin: auto;
  width: 100px;
}
 
 
 
.yearly-horoscope a {
  display: flex;
  align-items: center;
}
 
.yearly-horoscope a:before,
.yearly-horoscope a:after {
  flex: 1;
  content: '';
  height: 1px;
  background: #dcdcdc;
}
 
.yearly-horoscope p {
  color: #616161;
  margin-top: 20px;
  font-size: 18px;
}
 
.yearly-horoscope .card {
  background-color: #fff;
  box-shadow: 0 0 10px 2px #dbdbdb;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 25px;
  transition-timing-function: ease-in-out;
  transition-delay: .2s;
  transition-duration: .3s;
  border: none;
  min-height: 260px;
}
 
.yearly-horoscope .card img{
  width: 100%;
  height: 150px;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
  border: 1px solid #dcdcdc;
}
 
 
.yearly-horoscope .card h3 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 3px;
  text-transform: capitalize;
  color: #555;
  transition: .3s ease-in-out;
  text-decoration: none;
}
 
.yearly-horoscope .card h5 {
  font-size: 18px;
  margin-bottom: 0;
  color: #616161;
  text-align: justify;
  text-decoration: none;
  margin-top: 10px;
  line-height: 1.5;
}
 
.yearly-horoscope .card h5 .readMore{
  color: #313131;
  font-weight: 600;
  white-space: nowrap;
  transition: all .3s ease-in-out;
  font-size: 14px;
  display: inline-block;
}
 
.yearly-horoscope .card h5 .readMore::before{
  content: none !important;
}
 
.yearly-horoscope .card h5 .readMore::after{
  content: none !important;
}
 
.yearly-horoscope .aries .aries-search{
    background-color: #eee;
    border-radius: 5px;
    padding-top: 12px;
    box-shadow: none;
}
 
.yearly-horoscope .aries .aries-search h2{
  font-size: 19px;
  font-weight: 600;
  color: #313131;
  margin-bottom: 10px;
  text-transform: uppercase;
  text-align: left;
}
 
.yearly-horoscope .aries .aries-search .form-select{
    width: 100%;
    padding: 10px;
    font-size: 18px;
    border-radius: 10px;
    border: 1px solid #919191;
    color: #333;
    background-color: #fff;
}
/* Education */
 
.astroveda-education .card{
  border: none !important;
  box-shadow: inherit !important;
  background-color: transparent !important;
}
 
.astroveda-education .card h3{
  border: none;
  box-shadow: none;
  margin-bottom: 15px;
  font-size: 21px;
}
 
.astroveda-education .card img {
  border-radius: 0;
  height: auto;
  transition: transform 0.3s ease-in-out;
  object-fit: cover;
}
 
.astroveda-education .card img:hover {
  transform: scale(1.1); /* Scale image by 10% */
}